import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';

const NotFound = () => (
  <Layout>
    <Seo title="404 Not Found" description="ページが見つかりません" />
    <div className="not-found-message">
      <h1>404 Not Found</h1>
      <Link to="/">トップページへ</Link>
    </div>
  </Layout>
);

export default NotFound;
